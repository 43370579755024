export namespace PERMISSIONS {

    export namespace user {
        export const CRO = ['frost_index.act_as_cro']
        export const GENERIC = ['frost_index.readonly_user']
        export const STF = ['frost_index.act_as_stf']
        export const LOCAL_STF = ['frost_index.act_as_local_stf']
        export const FS = ['frost_index.conduct_ih_trials']
        export const DLO = ['frost_index.manage_cros']
        export const RTM = ['frost_index.manage_protocol']
        export const SEEDCARE_RTM_SUPPORT = ['frost_index.act_as_seedcare_rtm_support']

        export const ACTIVITY_COUNTRY_LEAD = ['frost_index.lead_activity_country_trials']

        export const FROST_FS_COUNTRY_LEAD = ['frost_index.lead_frost_country_trials']

        export const EQUIPMENT_COUNTRY_ADMIN = ['frost_index.manage_country_equipment']

        export const SEEDCARE_COUNTRY_LEAD = ['frost_index.lead_seedcare_country_trials']

        export const SYSTEM = ['frost_index.administrate_application']
        export const ADMIN_FROST = ['frost_index.administrate_frost_app']
        export const ADMIN_ACTIVITY = ['frost_index.administrate_activity_app']
        export const ADMIN_EQUIPMENT = ['frost_index.administrate_equipment_app']
        export const ADMIN_SEEDCARE = ['frost_index.administrate_seedcare_app']
        export const ADMIN_CHEMICALS = ['frost_index.administrate_chemicals_app']
        export const ADMIN_RESISTANCE_SAMPLING = ['frost_index.manage_resistance_sampling_app']

        // Generic

        export const ACTIVITY_GENERIC = ['frost_index.readonly_activity']
        export const CHEMICALS_GENERIC = ['frost_index.readonly_chemicals']
        export const EQUIPMENT_GENERIC = ['frost_index.readonly_equipment']
        export const FROST_GENERIC = ['frost_index.readonly_frost']
        export const SEEDCARE_GENERIC = ['frost_index.readonly_seedcare']

        // Other
        export const DATA_QUALITY_MANAGER = ['frost_index.manage_data_quality']


    }

    export namespace FROST {
        export const PERMISSIONS = ['frost_index.access_frost_app']
        export namespace Ordering {
            export const ANY_PERMISSIONS = ["frost_ordering.add_raworder", "frost_ordering.view_raworder", ...user.FROST_GENERIC]
            export namespace Assign {
                export const ANY_PERMISSIONS = ["frost_index.manage_cros", 'frost_index.administrate_frost_app', ...user.FROST_GENERIC]
            }
            export namespace Create {
                export const ANY_PERMISSIONS = ["frost_ordering.add_raworder", 'frost_index.administrate_frost_app', ...user.FROST_GENERIC]
            }
            export namespace Report {
                export const ANY_PERMISSIONS = ["frost_ordering.view_raworder", ...user.FROST_GENERIC]
            }
            export namespace TDManagement {
                export const ANY_PERMISSIONS = ["frost_ordering.view_tdownermanagement", "frost_ordering.change_tdownermanagement"]
            }
            export namespace FlaggedData {
                export const PERMISSIONS = ['frost_ordering.change_flaggedorderdata']
            }
        }
        export namespace Reservation {
            export const ANY_PERMISSIONS = ['reservation.view_reservationdata', 'frost_ordering.view_samplemanagement', ...user.FROST_GENERIC]
            export namespace SampleManagement {
                export const ANY_PERMISSIONS = ['frost_ordering.view_samplemanagement', ...user.FROST_GENERIC]
            }
        }

        export namespace Tracking {
            export const ANY_PERMISSIONS = ["frost_ordering.view_trackingreport", "frost_ordering.change_trackingreport", ...user.FROST_GENERIC]
        }


        export namespace Forecast {
            export const ANY_PERMISSIONS = ["forecast.add_forecast", "forecast.view_forecast", ...user.FROST_GENERIC]

            export namespace Add {
                export const ANY_PERMISSIONS = ['forecast.add_forecast', ...user.FROST_GENERIC]
            }
        }
    }


    export namespace Seedcare {
        export const PERMISSIONS = ['frost_index.access_seedcare_app']
        export namespace TreatmentRequest {
            export namespace RequestOverview {
                export const ANY_PERMISSIONS = [...user.RTM, ...user.STF, ...user.LOCAL_STF, ...user.SEEDCARE_RTM_SUPPORT, ...user.ADMIN_SEEDCARE, ...user.SEEDCARE_GENERIC]
            }
            export namespace RTMRequestTrial {
                export const ANY_PERMISSIONS = [...user.RTM, ...user.ADMIN_SEEDCARE, ...user.SEEDCARE_RTM_SUPPORT, ...user.SEEDCARE_GENERIC]
            }
        }


        export namespace TreatmentAssignment {
            export const ANY_PERMISSIONS = [...user.FS, ...user.CRO, ...user.DLO, ...user.ADMIN_SEEDCARE, ...user.SEEDCARE_GENERIC]
        }
        export namespace STFProtocolsPerTrial {
            export const ANY_PERMISSIONS = [...user.ADMIN_SEEDCARE, ...user.SEEDCARE_GENERIC, ...user.STF, ...user.LOCAL_STF]
        }
        export namespace STFShipping {
            export const ANY_PERMISSIONS = [...user.SEEDCARE_GENERIC, ...user.STF, ...user.ADMIN_SEEDCARE, ...user.SEEDCARE_RTM_SUPPORT, ...user.RTM]
        }


        export namespace InfectedSeeds {
            export const ANY_PERMISSIONS = ['seedcare_infected_seeds.view_infectedseed', ...user.SEEDCARE_GENERIC]
        }
    }


    export namespace Utilities {
        
        export const ANY_PERMISSIONS = [...user.SYSTEM, ...user.DATA_QUALITY_MANAGER, ...user.FS, ...user.ADMIN_EQUIPMENT, ...user.EQUIPMENT_COUNTRY_ADMIN, ...user.ADMIN_RESISTANCE_SAMPLING]
        export namespace DataQuality {
            export const ANY_PERMISSIONS = [...user.SYSTEM, ...user.DATA_QUALITY_MANAGER]
        }
        export namespace UtilityIdentification {
            export const ANY_PERMISSIONS = [...user.SYSTEM, ...user.FS, ...user.ADMIN_EQUIPMENT, ...user.EQUIPMENT_COUNTRY_ADMIN, ...user.ADMIN_RESISTANCE_SAMPLING]
        }
    }
    export namespace ResistanceSampling {
        export const PERMISSIONS = ['frost_index.access_resistance_sampling_app']

        export namespace SampleDemand {
            export const ANY_PERMISSIONS = [...user.SYSTEM, ...user.ADMIN_RESISTANCE_SAMPLING, ...user.RTM, ...user.FS]
            export namespace Create {
                export const ANY_PERMISSIONS = [...user.SYSTEM, ...user.ADMIN_RESISTANCE_SAMPLING, ...user.RTM]
            }

            export namespace Report {
                export const ANY_PERMISSIONS = [...user.SYSTEM, ...user.ADMIN_RESISTANCE_SAMPLING, ...user.FS, ...user.ADMIN_RESISTANCE_SAMPLING]
            }
        }
    }


    export namespace Equipment {
        export const PERMISSIONS = ['frost_index.access_equipment_app']
        export const ANY_APPROVE_REJECT_REQUEST = ['equipments_stock.approve_reject_request', ...user.FS, ...user.ADMIN_EQUIPMENT, ...user.EQUIPMENT_GENERIC]
        export const ANY_ADD_DEVICE_INFORMATION = ['equipments_stock.add_devicestorageinformation', ...user.EQUIPMENT_GENERIC]

    }


    export namespace Chemicals {
        export const ANY_PERMISSIONS = ['frost_index.access_chemicals_app', 'frost_index.view_tracking_report', 'frost_index.change_tracking_report']

        export namespace Products {
            export namespace Add {
                export const ANY_PERMISSIONS = ['chemicals_stock.add_chemicalproductgroupbranch', ...user.CHEMICALS_GENERIC]
            }
        }

        export namespace Branches {

            export const ANY_PERMISSIONS = ['chemicals_stock.view_chemicalbranch', ...user.CHEMICALS_GENERIC]

            export namespace List {
                export const ANY_PERMISSIONS = ['chemicals_stock.view_chemicalbranch_list', ...user.CHEMICALS_GENERIC]
            }

        }

        export namespace Tracking {
            export const ANY_PERMISSIONS = ['frost_ordering.view_trackingreport', 'frost_ordering.change_trackingreport']
        }
    }


    export namespace Activity {
        export const PERMISSIONS = ['frost_index.access_activity_app']
    }


    export namespace People {
        export const ANY_PERMISSIONS = ['frost_index.change_user', 'frost_index.view_user', 'frost_index.change_inhousestationaddress', 'frost_index.change_croaddress']
    }



}