import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import SecureLS from "secure-ls"
import { getCurrentDate, uploadFile } from "./api"
import { MRT_SortingState } from "material-react-table"
import { ActionButton, Callout, DefaultButton, FontSizes, FontWeights, Modal, PrimaryButton, Stack, Text } from "@fluentui/react"
import { downloadFileFromBackend } from "Tools/file"
import { useId } from "@fluentui/react-components"
import { FilePicker } from "Components/FilePicker/FilePicker"
import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { TickContext } from "./context"
import { TOAST_API } from "Tools/ui"
import { useAuthContext } from "Data/auth/hooks"
import { arrayDistinctValues } from "Tools/object"


export const useLocalStorage = () => {
    let storage = new SecureLS({ encodingType: 'aes' })

    let add = (key: string, value: string) => storage.set(key, value)

    let remove = (key: string) => storage.remove(key)

    let getItem = (key: string) => storage.get(key)

    return { add, remove, getItem }
}


export const useNow = (invoke = true) => {
    let [now, setNow] = useState(new Date)
    let updateNow = useCallback(() => {
        return getCurrentDate().then(({ data }) => {
            let v = (data ? new Date(data) : now)
            setNow(v);
            return v
        })
    }, [now])
    useEffect(() => {
        invoke &&
            updateNow()
    }, [])
    return { now, updateNow }
}

export const useTick = () => {
    let [tick, setTick] = useState(0)
    return { tick, setTick }
}

export const useTickContext = () => {
    const context = useContext(TickContext)
    return context
}

export const useResolver = (onFinish?: () => any) => {
    let [loading, setLoading] = useState(false)
    async function resolve<T = any>(promise: Promise<api_res_t<T[]>>, successMessage = 'Saved changes', errorMessage = null as string | null, loadingMessage = 'Saving changes') {
        setLoading(!0)
        let res = await TOAST_API.resolve(promise, successMessage, errorMessage, loadingMessage)
        setLoading(!1)
        onFinish?.call(null)
        return res
    }

    return { loading, resolve }
}
export const useProcessingProvide = () => {

    let [$savingData, $setSavingData] = useState(false)
    let [$isLoading, $setIsLoading] = useState(false)
    let [$errorOccured, $setErrorOccured] = useState<string | false>(false)
    let [$dataSaveFailed, $setDataSaveFailed] = useState<string | false>(false)
    let [$dataSavedSuccesfully, $setDataSavedSuccesfully] = useState<boolean>(false)

    return {
        $savingData,
        $setSavingData,
        $isLoading,
        $setIsLoading,
        $errorOccured,
        $setErrorOccured,
        $dataSaveFailed,
        $setDataSaveFailed,
        $dataSavedSuccesfully,
        $setDataSavedSuccesfully
    }
}

interface TableOptions<T, V = any, K = any> {
    noBlankFilter?: boolean
    exportedFileName?: string | (() => string)
    globalFilterName?: string
    exportDataUrl?: string
    transformList?: (data: T[]) => K[],
    fileUploadUrl?: string
    defaultPageSize?: undefined | number,
    fileUploadSuccessParser?: (data: any) => string
    mapList?: (data: T, i?: number, a?: T[]) => V
    wordListColumnFilterFields?: string[]
}

export type biased_response_t<T> = ({ list: T[] | null } | { data: T | null }) & { error: null | string }
type naive_response_t<T> = { list: T[] | null, data: T | null, count: number, meta?: any, next?: string, previous?: string, originalResponse?: any } & { error: null | string }




/**
 * A convenience hook for interacting with `<MaterialReactTable/>` complete with pagination and filtering options
 * @param fetchFunction A function which calls the api returning the required table data
 * @param props Additional optional properties
 * @param deps Dependencies
 * @returns An object which can be used with `<MaterialReactTable/>
 */
export const useTable = <T, M = any, N = any, META = any, FILTERS_T = rec_t<strnum_t, any>>(fetchFunction?: (filters: any) => Promise<biased_response_t<T>>, props = { noBlankFilter: false, globalFilterName: undefined } as TableOptions<T, M, N>, deps = [] as any[]) => {
    let [filters, setFilters] = useState<FILTERS_T>({} as FILTERS_T)
    let [data, setData] = useState([] as T[])
    let [meta, setMeta] = useState<META>(null as META)
    let [mappedData, setMappedData] = useState([] as M[])
    let [transformedData, setTransformedData] = useState([] as N[])
    let [paginationUrls, setPaginationUrls] = useState<mui_pagination_urls_t>({} as any)
    let [sorting, setSorting] = useState<MRT_SortingState>([])
    let [globalFilter, setGlobalFilter] = useState('')
    let [rowCount, setRowCount] = useState(0)// Number of items in the database matching the provided query
    let [globalFilterShown, setGlobalFilterShown] = useState(false)
    let [dataIsLoading, setDataIsLoading] = useState(false)
    let [columnFilters, setColumnFilters] = useState<{ id: any, value: any }[]>([])
    let [dataExportOptionsShown, setDataExportOptionsShown] = useState(false)
    let [fileUploadModalShown, setFileUploadModalShown] = useState({} as { file?: File | null, uploading?: boolean, shown?: boolean })
    let [pagination, setPagination] = useState<mui_pagination_t>({ pageIndex: 0, pageSize: props.defaultPageSize || 40 });
    let [exportFileName, setExportFileName] = useState('')
    let [fileToUpload, setFileToUpload] = useState(null as File | undefined | null)
    let [loadCounter, setLoadCounter] = useState(0)
    let [allFilters, setAllFilters] = useState<rec_t>({})
    let [showColumnFilters, onShowColumnFiltersChange] = useState(false)
    let [_globalFilterTimeout, _setGlobalFilterTimeout] = useState(null as any)
    let [_globalFilter, _setGlobalFilter] = useState('')
    let tableId = useId('cpd-')

    let loading = useMemo(() => {
        return dataIsLoading || fileUploadModalShown.uploading
    }, [dataIsLoading, fileUploadModalShown.uploading])

    let _forceFetch = useCallback(() => {
        let _globalFilterLocalRef = {} as any
        let _filters = { ...filters, page: pagination.pageIndex + 1, pageSize: pagination.pageSize }
        let _columnFilters = {} as any
        columnFilters.forEach(({ id, value }) => {
            if (Array.isArray(value)) {
                if (props?.wordListColumnFilterFields?.includes(id)) {
                        _columnFilters[id.replaceAll(/\./g, '__')+'_in']=value.join()
                } else {
                    let [minV, maxV] = value
                    _columnFilters[id.replaceAll(/\./g, '__') + '_gte'] = !/\d+/.test(minV + '') ? undefined : minV;
                    _columnFilters[id.replaceAll(/\./g, '__') + '_lte'] = !/\d+/.test(maxV + '') ? undefined : maxV;
                }
            } else
                _columnFilters[id.replaceAll(/\./g, '__')] = value;
        })
        if (props.globalFilterName && globalFilterShown) {
            _globalFilterLocalRef[props.globalFilterName] = _globalFilter
            _filters.page = undefined as any
        }


        if (fetchFunction) {
            setDataIsLoading(true)
            let sorting_ = [] as string[]
            let allParams = {}
            for (let { id, desc } of [...sorting])
                sorting_.push(`${(desc ? '' : '-')}${id.replaceAll(/\./g, '__')}`)
            allParams = { ..._filters, ..._columnFilters, sorting: sorting_.join(), ...(_globalFilterLocalRef) }
            setAllFilters(allParams)
            fetchFunction(allParams)
                .then((res) => {
                    let { list, count, meta: meta_ } = res as naive_response_t<T>
                    let pageSize = list?.length || 0
                    let list_ = list || []
                    setData(list_)
                    setMappedData(props.mapList ? list_.map<M>(props.mapList) : [])
                    setTransformedData(props.transformList ? props.transformList(list_ || []) : [])
                    // setPagination({...pagination})
                    setMeta(meta_)
                    setRowCount(count || pageSize)
                }).finally(() => setDataIsLoading(false))
        }
    }, [filters, pagination, columnFilters, globalFilterShown, sorting])


    let _uploadFile = async (file: File) => {
        let error = null as any, res = null as any
        if (!file) return toast.error('Select a file first')
        toast.loading('Uploading file')
        setFileUploadModalShown({ ...fileUploadModalShown, uploading: true });
        ({ error, data: res } = await uploadFile(props.fileUploadUrl!, file!));
        toast.dismiss()
        toast[error ? 'error' : 'success'](error || `Successfuly uploaded. ${props.fileUploadSuccessParser?.call(null, res)}`)
        setFileUploadModalShown({ ...fileUploadModalShown, uploading: false });
        if (!error) {
            _forceFetch()
            setFileToUpload(null)
            setFileUploadModalShown({})
        }
    }


    let exportToExcel = useCallback(() => {
        let { exportedFileName: propFileName = '', exportDataUrl = '' } = props || {}
        let filename = exportFileName || (typeof propFileName == 'function' ? propFileName() : propFileName)
        setDataExportOptionsShown(false)
        downloadFileFromBackend(
            exportDataUrl || '',
            `${filename || 'exported-document'}.xlsx`,
            { data_format: "excel", ...allFilters }
        );
    }, [allFilters, exportFileName])

    let exportToPdf = useCallback(() => {
        let { exportedFileName: propFileName = '', exportDataUrl = '' } = props || {}
        let filename = exportFileName || (typeof propFileName == 'function' ? propFileName() : propFileName)
        setDataExportOptionsShown(false)
        downloadFileFromBackend(
            exportDataUrl || '',
            `${filename || 'exported-document'}.pdf`,
            { data_format: "pdf", ...allFilters }
        );
    }, [allFilters, exportFileName])


    let ExportButton = useMemo(() => {
        type props_t = {
            disabled?: boolean
            annotation?: string | ''
        }
        return (props: props_t) => <ActionButton disabled={props.disabled} id={'exports-' + tableId} onClick={(_) => setDataExportOptionsShown(true)} iconProps={{ iconName: "Share" }}>Export {props.annotation}</ActionButton>
    }, [tableId])
    let FileUploadModal = useMemo(() => {
        return () => {
            return <>
                <Modal
                    dragOptions={{ menu: () => <></>, moveMenuItemText: 'Move', closeMenuItemText: 'Close' }}
                    styles={{ main: { minWidth: '400px' } }}
                    isOpen={!!fileUploadModalShown.shown}
                    isClickableOutsideFocusTrap={false}
                    onDismiss={_ => setFileUploadModalShown({ file: fileUploadModalShown.file })}
                    isModeless>
                    <Stack style={{ padding: '1rem' }}>
                        <Text style={{ fontSize: FontSizes.large, fontWeight: FontWeights.semibold }}>Select a file</Text>

                        <FilePicker file={fileToUpload} accept=".csv,.xlsx" style={{ marginTop: '2rem', width: '100%', height: 200, marginInline: 'auto' }} onFileChanged={file => { file && setFileToUpload(file) }} />

                        <Stack horizontal horizontalAlign="end" style={{ gap: '1rem', paddingTop: '3rem' }}>
                            <DefaultButton disabled={loading} onClick={_ => setFileUploadModalShown({})} style={{ alignSelf: 'end' }}>Cancel</DefaultButton>
                            <PrimaryButton disabled={loading || !fileToUpload} onClick={_ => _uploadFile(fileToUpload!)} style={{ alignSelf: 'end' }}>Upload</PrimaryButton>
                        </Stack>
                    </Stack>
                </Modal></>
        }

    }, [loading, fileUploadModalShown.shown, fileToUpload])

    let FileUploadButton = useMemo(() => {
        type props_t = { disabled?: boolean }
        return (props: props_t) => <ActionButton disabled={loading || props.disabled} onClick={(_) => setFileUploadModalShown({ shown: true })} iconProps={{ iconName: "Upload" }}>File upload</ActionButton>
    }, [])
    let ExportOptionsDropdown = useMemo(() => {
        return () => <>{!!dataExportOptionsShown && <Callout
            isBeakVisible={true}
            dismissOnTargetClick
            setInitialFocus
            onDismiss={() => setDataExportOptionsShown(false)}
            style={{ padding: "0" }}
            target={'#exports-' + tableId}
        >
            <ActionButton onClick={exportToExcel} iconProps={{ iconName: "ExcelDocument" }}>Excel</ActionButton>
        </Callout>}</>

    }, [dataExportOptionsShown, tableId])

    let ExportExcelAndPdfOptionsDropdown = useMemo(() => {
        return () => <>{!!dataExportOptionsShown && <Callout
            isBeakVisible={true}
            dismissOnTargetClick
            setInitialFocus
            onDismiss={() => setDataExportOptionsShown(false)}
            style={{ padding: "0" }}
            target={'#exports-' + tableId}
        >
            <ActionButton onClick={exportToExcel} iconProps={{ iconName: "ExcelDocument" }}>Excel</ActionButton>
            <ActionButton onClick={exportToPdf} iconProps={{ iconName: "PDF" }}>Pdf</ActionButton>
        </Callout>}</>

    }, [dataExportOptionsShown, tableId])
    let ShowColumnFiltersToggle = useMemo(() => {
        return () =>
            <ActionButton onClick={_ => onShowColumnFiltersChange(!showColumnFilters)} iconProps={{ iconName: "BarChartVerticalFilter" }}>{showColumnFilters ? 'Close' : 'Open '} Filters</ActionButton>

    }, [showColumnFilters])

    let forceFetch = () => {
        setLoadCounter(c => c + 1)
    }

    useEffect(() => {
        setPagination({ ...pagination, pageIndex: 0 })
    }, [columnFilters, globalFilter])

    useEffect(() => {
        let { noBlankFilter } = props
        noBlankFilter = noBlankFilter != undefined && noBlankFilter
        if (noBlankFilter && !Object.keys({ ...columnFilters, ...filters }).length) return setData([])
        _forceFetch()
    }, [filters, loadCounter, columnFilters, sorting, pagination, _globalFilter, globalFilterShown, ...deps])

    useEffect(() => {
        clearTimeout(_globalFilterTimeout)
        _setGlobalFilterTimeout(setTimeout(() => _setGlobalFilter(globalFilter || '')))
    }, [globalFilter])
    return {
        forceFetch,
        sorting,
        meta,
        rowCount,
        loading,
        clearData: () => setData([]),
        setData,
        ExportOptionsDropdown,
        ExportExcelAndPdfOptionsDropdown,
        ExportButton,
        FileUploadModal,
        FileUploadButton,
        setExportFileName,
        setRowCount,
        setSorting,
        filters,
        allFilters,
        setFilters,
        columnFilters,
        setColumnFilters,
        globalFilter,
        setGlobalFilter,
        paginationUrls,
        setPaginationUrls,
        pagination,
        data,
        mappedData,
        transformedData,
        setPagination,
        globalFilterShown,
        setGlobalFilterShown,
        showColumnFilters,
        onShowColumnFiltersChange,
        ShowColumnFiltersToggle
    }
}

export const useFSCountriesLed = (module_name: string) => {
    let { user } = useAuthContext()
    return (user?.fs_ih_countries_led || {})[module_name] as Country[] || undefined
}

interface FetchOptions<K, M, KO = K> {
    invoke?: boolean
    /**Pick a single item and assign it to `data` */
    getOne?: (a: K[]) => KO
    map?: (v: K, i?: number, a?: K[]) => M
    callbackMapped?: (res: M[]) => void
    pageinationDistinctFilterFn?: (v: K, i?: number, a?: K[]) => boolean
    /**
     * Specify the key used to run through the filter in pagination.
     * -1 will filter by value while any other key will filter by that key.
     * Default is `'id'`
     */
    paginationDistinctFilterKey?: string | -1
    afterFetch?: (a: K[]) => void
    applyPaginationOptions?: boolean
    noPaginationAgggregation?: boolean
    disableEmptyParams?: boolean
    clearResultsOnEmptyParams?: boolean
    data?: 'originalResponse' | 'list'
}

/**
 * The objective is to reduce on the code involved using isolated state variables and hooks
 * @param func Function invocked to get the resource 
 * @param invoke Whether or not `func` should be called. There are instances when we would not like to call `func` due to some condition
 * @param deps Dependencies 
 * @returns A state object with `list` and `data` fields holding the results
 */
export const useFetch = <T, M = T, META = any, P = Record<strnum_t, any>, DOut = T>(func: (params: P) => Promise<biased_response_t<T>>, deps = [] as any[], options?: FetchOptions<T, M, DOut>) => {
    let [error, setError] = useState(null as string | null)
    let [data, setData] = useState(null as DOut | null)
    let [meta, setMeta] = useState<META>(null as META)

    let [list, setList] = useState(null as T[] | null)
    let [loadedPagesContent, setLoadedPagesContent] = useState([] as T[])
    let [currentPageIndex, setCurrentPageIndex] = useState(null as number | null)
    let [nextPageParams, setNextPageParams] = useState(null as any)
    let [previousPageParams, setPreviousPageParams] = useState(null as any)
    let [list$, setList$] = useState([] as T[])
    let [mapedList$, setMapedList$] = useState([] as M[])
    let [loading, setLoading] = useState(false)
    let [params, setParams] = useState<P>({} as P)
    let [callback, setCallback] = useState<((l: T[] | null, d: T | null, e: any) => void) | null>(null)
    let [count, setCount] = useState(0)
    let options_ = useMemo(() => {
        return { ...(options as any), invoke: options?.invoke !== undefined ? !!options.invoke : true } as FetchOptions<T, M>
    }, [options])

    /**
     * Force a new call to the function to happen
     * @param force Overides the option `invoke`
     */
    let load = (force = false) => {
        // if (!Object.keys(params || {}).filter(x => typeof (params || {} as any)[x as any] != 'undefined').length && options?.disableEmptyParams) return;
        if (options?.disableEmptyParams && !Object.values(params || {}).filter(x => !!x).length) {
            if (options?.clearResultsOnEmptyParams) {
                setData(null)
                setList([])
                setMapedList$([])
                setList$([])
            }
            return
        }
        if (force || options_.invoke || Object.keys(params || {} as any).length) {
            setLoading(true)
            func({ ...params, ADAPT_PAGINATION: !!options?.applyPaginationOptions || undefined }).then(result => {
                let { list = null, data = null, error, count, previous, next, meta, originalResponse } = result as naive_response_t<T>
                let mapped = options_?.map ? (list || []).map(options_.map) : []
                callback?.(list, data, error)
                setCallback(null);
                setList(list || null);
                setError(error || null);
                setMeta(meta);
                setData(options?.data != 'originalResponse' ? (list?.length && options_.getOne ? options_.getOne(list) : data) as T | null : originalResponse);
                setList$(list || [])
                setCount(count || list?.length || 0)
                options?.map && setMapedList$(mapped)
                options?.callbackMapped && options.map && options.callbackMapped(mapped)
                options?.afterFetch?.(list || [])


                if (options?.applyPaginationOptions) {
                    let nextUrl = next ? new URL(next) : null
                    let previousUrl = previous ? new URL(previous) : null
                    let nextSearchParams = new URLSearchParams(nextUrl?.search)
                    let previousSearchParams = new URLSearchParams(previousUrl?.search)
                    let nextPage = nextUrl ? nextSearchParams.get('page') : null
                    let previousPage = previousUrl ? previousSearchParams.get('page') : null
                    setLoadedPagesContent(list || [])

                    if (/\d+/.test(nextPage || ''))
                        setNextPageParams(Object.fromEntries(nextSearchParams) as any)
                    else setNextPageParams(null)

                    if (/\d+/.test(previousPage || ''))
                        setPreviousPageParams(Object.fromEntries(previousSearchParams) as any)
                    else setPreviousPageParams(null)

                }


            }).finally(() => {
                setLoading(false)
            })
        }
    }
    let loadPage = (params: any) => {
        func({ ...params }).then(res => {
            let { list = null, data = null, error, count, next, previous } = res as naive_response_t<T>
            let nextUrl = next ? new URL(next) : null
            let previousUrl = previous ? new URL(previous) : null
            let nextPage = nextUrl ? new URLSearchParams(nextUrl.search).get('page') : null
            let previousPage = previousUrl ? new URLSearchParams(previousUrl.search).get('page') : null
            let concated = options?.noPaginationAgggregation ? list || [] : loadedPagesContent.concat(list || [])

            if (!options?.noPaginationAgggregation) {
                if (options?.pageinationDistinctFilterFn) {
                    concated = concated.filter(options?.pageinationDistinctFilterFn)
                }
                else concated = concated.filter((v: any, i, a: any[]) => {
                    let filterKey = options?.paginationDistinctFilterKey || 'id'
                    return filterKey != -1 ? a.findIndex(v1 => v1?.[filterKey] == v?.[filterKey]) == i : a.findIndex(v1 => v1 == v) == i
                })
            }
            if (error) {
                return setLoadedPagesContent([])
            }
            setLoadedPagesContent(concated)

            setCurrentPageIndex(nextPage ? +nextPage - 1 : previousPage ? +previousPage + 1 : 1)

            if (nextPage)
                if (nextPage && /\d+/.test(nextPage || ''))
                    setNextPageParams(Object.fromEntries(new URLSearchParams(nextUrl?.search)) as any)
                else setNextPageParams(null)
            else setNextPageParams(null)

            if (previousPage)
                if (previousPage && /\d+/.test(previousPage || ''))
                    setPreviousPageParams(Object.fromEntries(new URLSearchParams(previousUrl?.search)) as any)
                else setPreviousPageParams(null)
            else setPreviousPageParams(null)
        })
    }
    let resetData = () => {
        setList(null)
        setList$([])
        setError(null)
        setLoading(false)
        setData(null)
    }
    useEffect(() => {
        load()
    }, [...deps, params])

    return {
        load,
        loading,
        setLoading,
        setParams,
        resetData,
        error,
        mapedList$,
        setList$,
        setMeta,
        meta,
        list,
        list$,
        data,
        setData,
        setList,
        params,
        count,
        loadPage,
        nextPageParams,
        previousPageParams,
        loadedPagesContent,
        setLoadedPagesContent,
        setNextPageParams,
        currentPageIndex,
        callback,
        setCallback
    }
}

