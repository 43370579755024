
import { AxiosError, AxiosResponse, isAxiosError } from "axios";

export function processResponse<T>(axsiosResponse: AxiosResponse<T>) {
    let status: number, data: T, x: any
    ({ status, data, ...x } = axsiosResponse || {});

    if (status >= 200 && status < 400) {
        return { data, error: null, originalError: null }
    } else {
        let errorResponse = ((axsiosResponse as unknown) as AxiosError)?.response?.data || {} as any
        let originalError = ((axsiosResponse as unknown) as AxiosError)?.response?.data || {} as any
        let { error = 'Unknown error', } = errorResponse

        if (x.code == 'ERR_NETWORK') {
            originalError = { detail: navigator.onLine ? 'Connection to server interrupted or unavailable' : 'Network error' }
        }
        return { data: null, error, originalError, status }
    }
}

export function processResultsResponse<T extends Array<any>, META = any>(axsiosResponse: AxiosResponse<ApiResultsResponse<T, META>>) {
    let list: T | null
    let { data, error, originalError } = processResponse(axsiosResponse)
    list = !error && Array.isArray(data?.results) && data!.results || (data?[data]:null) as any
    return { list, meta: data?.meta, error, originalError, originalResponse: data as any, next: data?.next || null, previous: data?.previous || null, count: data?.count || null }
}


export enum RequestStatus {
    BLANK = 0,
    WAITING = 1,
    SUCCESS = 2,
    ERROR = 3
}

