import { Icon } from "@fluentui/react"
import { toast } from "react-toastify"

export const TOAST_API = {
    async resolve<T>(promise: Promise<any>, successMessage = 'Saved changes', errorMessage = null as string | null,loadingMessage='Saving changes') {
        toast.loading(loadingMessage, { position: toast.POSITION.TOP_RIGHT })
        let error=null as null|undefined|any, data=null as null|undefined|any, list =null as null|undefined|any[],originalResponse=null as nul_undef_t|any
        ({ error, data, list ,originalResponse} = await promise);
        toast.dismiss()
        toast[error ? 'error' : 'success'](error ? errorMessage || error : successMessage, { position: toast.POSITION.BOTTOM_RIGHT })
        return { error, data, list,originalResponse} 
    },
    async copyLink(link: string, message = 'Copied link to clipboard') {
        window.navigator.clipboard
            .writeText(link)
            .then(() => toast.success(<>{message}</>,
                { hideProgressBar: true, autoClose: 500, icon: <Icon iconName="Copy" /> })
            );
    }
}

